import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';
import { PolishWarehouseApiService } from './services/polish-warehouse-service';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HomeComponent } from './home/home.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { PolishListComponent } from './polish-list/polish-list.component';
import { PolishDetailsComponent } from './polish-details/polish-details.component';
import { TabDirective } from '../app/services/tab-directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { BulkLocationChangeDialogComponent } from './bulk-location-change-dialog/bulk-location-change-dialog.component';
import { ImageCarouselDialogComponent } from './image-carousel-dialog/image-carousel-dialog.component';
import { UserService } from './services/user-service';
import { PolishViewComponent } from './polish-view/polish-view.component';
import { RandomPolishComponent } from './random-polish/random-polish.component';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { NgImageSliderModule } from 'ng-image-slider';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavBarComponent,
    SpinnerOverlayComponent,
    PolishListComponent,
    PolishDetailsComponent,
    TabDirective,
    ConfirmationDialogComponent,
    BulkLocationChangeDialogComponent,
    ImageCarouselDialogComponent,
    PolishViewComponent,
    RandomPolishComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'polish-details', component: PolishDetailsComponent }
    ]),
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    NgImageSliderModule
  ],
  providers: [PolishWarehouseApiService, UserService, DatePipe, MatAutocomplete],
  bootstrap: [AppComponent]
})
export class AppModule { }
