<h1 mat-dialog-title>Confirm</h1>
<div mat-dialog-content>
  {{message}}
  <div class="image-center" *ngIf="image">
    <img src="{{image}}">
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No</button>
  <button mat-button (click)="onYesClick()" cdkFocusInitial>Yes</button>
</div>