import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Brand, Color, Item, PolishType } from '../core/models/polish-models';
import brands from '../configuration/brands.json';
import colors from '../configuration/colors.json';
import polishTypes from '../configuration/polishTypes.json';
import api from '../configuration/api.json';

@Component({
  selector: 'app-polish-view',
  templateUrl: './polish-view.component.html',
  styleUrls: ['./polish-view.component.scss']
})
export class PolishViewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PolishViewComponent>,
    @Inject(MAT_DIALOG_DATA) public polish: Item
  ) { }
  Polish: Item;
  Brands: Brand[] = null;
  Colors: Color[] = null;
  PrimaryColors: Color[] = null;
  SecondaryColors: Color[] = null;
  GlitterColors: Color[] = null;
  PolishTypes: PolishType[] = null;

  SelectedSecondaryColors: Color[] = [];
  SelectedGlitterColors: Color[] = [];
  SelectedPolishTypes: PolishType[] = [];

  SelectedSecondaryColorsText: string = "";
  SelectedGlitterColorsText: string = "";
  SelectedPolishTypesText: string = "";

  Images: string[] = [];
  ImageObject: any[] = [];

  ngOnInit(): void {
    this.Polish = this.polish;
    this.Brands = brands;
    this.Colors = colors;
    this.PolishTypes = polishTypes;
    this.PrimaryColors = this.Colors.filter(color => color.IsPrimary);
    this.SecondaryColors = this.Colors.filter(color => color.IsSecondary);
    this.GlitterColors = this.Colors.filter(color => color.IsGlitter);

    if (this.Polish.GlitterColors != null) {
      let ids = this.Polish.GlitterColors?.split(',');
      ids.forEach(id => {
        this.SelectedGlitterColors.push(this.GlitterColors.filter(x => x.ID.toString() == id)[0]);
      });
    }

    if (this.Polish.SecondaryColors != null) {
      let ids = this.Polish.SecondaryColors?.split(',');
      ids.forEach(id => {
        this.SelectedSecondaryColors.push(this.SecondaryColors.filter(x => x.ID.toString() == id)[0]);
      });
    }

    if (this.Polish.PolishTypes != null) {
      let ids = this.Polish.PolishTypes?.split(',');
      ids.forEach(id => {
        this.SelectedPolishTypes.push(this.PolishTypes.filter(x => x.ID.toString() == id)[0]);
      });
    }

    this.SelectedSecondaryColorsText = this.SelectedSecondaryColors.length > 0 ? this.SelectedSecondaryColors.map(color => color.Name).join(",") : "None";
    this.SelectedGlitterColorsText = this.SelectedGlitterColors.length > 0 ? this.SelectedGlitterColors.map(color => color.Name).join(",") : "None";
    this.SelectedPolishTypesText = this.SelectedPolishTypes.length > 0 ? this.SelectedPolishTypes.map(color => color.Name).join(",") : "None";

    if (this.Polish.ImagePaths != null) {
      let paths = this.Polish.ImagePaths?.split(',');
      let url = api.filter(a => a.Name == "imageurl")[0].Code;
      let imagesas = api.filter(a => a.Name == "imagesas")[0].Code;
      paths.forEach(path => {
        let imageUrl = url + path + imagesas;
        this.Images.push(imageUrl);
        this.ImageObject.push({
          image: imageUrl,
          thumbImage: imageUrl,
          title: ''
        });
      });
      if (this.Images.length > 0) {
        this.dialogRef.updateSize('90%', '95%');
      }
    }
  }

  onClick(): void {
    this.dialogRef.close(null);
  }
}
