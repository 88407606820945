<h1 mat-dialog-title>{{Polish.Name}}</h1>
<div mat-dialog-content>
    <div class="container" fxLayout="wrap" fxLayout.xs="column" fxLayoutAlign="start" fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div class="item" fxFlex="100%">
            <mat-card>
                <mat-card-content>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field fxFlex="25%">
                            <mat-label>Brand Name</mat-label>
                            <input matInput disabled placeholder="Brand Name" [(ngModel)]="Polish.BrandName">
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Polish Name</mat-label>
                            <input matInput disabled placeholder="Polish Name" [(ngModel)]="Polish.Name">
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Color</mat-label>
                            <input matInput disabled placeholder="Color" [(ngModel)]="Polish.ColorName">
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Color Number</mat-label>
                            <input matInput disabled placeholder="Color Number" [(ngModel)]="Polish.ColorNumber">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field fxFlex="33%">
                            <mat-label>Secondary Colors</mat-label>
                            <input matInput disabled placeholder="Secondary Colors"
                                [(ngModel)]="SelectedSecondaryColorsText">
                        </mat-form-field>
                        <mat-form-field fxFlex="34%">
                            <mat-label>Glitter Colors</mat-label>
                            <input matInput disabled placeholder="Glitter Colors"
                                [(ngModel)]="SelectedGlitterColorsText">
                        </mat-form-field>
                        <mat-form-field fxFlex="33%">
                            <mat-label>Polish Types</mat-label>
                            <input matInput disabled placeholder="Polish Types" [(ngModel)]="SelectedPolishTypesText">
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="Images.length > 0">
                <mat-card-header>
                    <mat-card-title>Images</mat-card-title>
                </mat-card-header>
                <ng-image-slider #nav [images]="ImageObject" [infinite]="false" [autoSlide]="1"
                    [imageSize]="{width: '25%'}" [manageImageRatio]="true" slideImage="1"></ng-image-slider>
                <mat-card-content>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="Images.length == 0">
                <mat-card-header>
                    <mat-card-title>Images</mat-card-title>
                </mat-card-header>
                No Images to show!
                <mat-card-content>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onClick()">Close</button>
    </div>