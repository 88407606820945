import { Item } from "../models/polish-models";

export class GetItemRequest {
    id: string;
}

export class UpdatePolishesRequest {
    Polishes: Item[];
}

export class DestashPolishesRequest {
    Polishes: Item[];
}

export class DeletePolishesRequest {
    Polishes: Item[];
}

export class CreatePolishesRequest {
    Polishes: Item[];
}

export class UploadPolishImagesRequest {
    polishID: string;
}

export class DeletePolishImageRequest {
    polishID: string;
    imagePath: string;
    fileName: string;
}