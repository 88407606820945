import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Item } from '../core/models/polish-models';
import { CreatePolishesRequest, UpdatePolishesRequest, DeletePolishesRequest, DestashPolishesRequest, GetItemRequest, DeletePolishImageRequest } from '../core/requests/get-item-request';
import { CreatePolishesResponse, UpdatePolishesResponse, DeletePolishesResponse, DestashPolishesResponse, DeletePolishImageResponse } from '../core/responses/get-item-response';
import { Observable } from 'rxjs';
import api from '../configuration/api.json';

@Injectable()
export class PolishWarehouseApiService {
  constructor(private http: HttpClient) { }

  baseUrl: string = environment.baseUrl + 'api/';

  private GetHeaders(contentType: string = 'application/json; charset=utf-8'): HttpHeaders {
    let headers = new HttpHeaders().set('Accept', '*/*');
    return contentType !== null ? headers.set('Content-Type', contentType) : headers;
  }

  Get<T>(endpoint: string, code: string = "", contentType: string = 'application/json; charset=utf-8') {
    let url = code ? this.baseUrl + endpoint + "?code=" + code : this.baseUrl + endpoint;
    let response = this.http.get<T>(url);

    return response;
  }

  Post<T>(endpoint: string, body: any = null, code: string = "", queryParams: string = "", contentType: string = 'application/json; charset=utf-8') {
    let url = code ? this.baseUrl + endpoint + "?code=" + code : this.baseUrl + endpoint;
    if(queryParams){
      url = url + queryParams;
    }
    let headers = this.GetHeaders(contentType);
    let response = this.http.post<T>(url, body, { headers: headers });

    return response;
  }

  GetPolishes(): Observable<Item[]> {
    let controller = 'GetPolishes';
    let code = api.filter(a => a.Name == controller)[0].Code;
    return this.Get<Item[]>(controller, code);
  }

  GetPolish(id: string): Observable<Item> {
    let controller = 'GetPolish';
    let code = api.filter(a => a.Name == controller)[0].Code;
    let req = new GetItemRequest();
    req.id = id;
    return this.Post<Item>(controller, req, code);
  }

  CreatePolishes(polishes: Item[]): Observable<CreatePolishesResponse> {
    let controller = 'CreatePolishes';
    let code = api.filter(a => a.Name == controller)[0].Code;
    let req = new CreatePolishesRequest();
    req.Polishes = polishes;
    return this.Post<CreatePolishesResponse>(controller, req, code);
  }

  UpdatePolishes(polishes: Item[]): Observable<UpdatePolishesResponse> {
    let controller = 'UpdatePolishes';
    let code = api.filter(a => a.Name == controller)[0].Code;
    let req = new UpdatePolishesRequest();
    req.Polishes = polishes;
    return this.Post<UpdatePolishesResponse>(controller, req, code);
  }

  DeletePolishes(polishes: Item[]): Observable<DeletePolishesResponse> {
    let controller = 'DeletePolishes';
    let code = api.filter(a => a.Name == controller)[0].Code;
    let req = new DeletePolishesRequest();
    req.Polishes = polishes;
    return this.Post<DeletePolishesResponse>(controller, req, code);
  }

  DestashPolishes(polishes: Item[]): Observable<DestashPolishesResponse> {
    let controller = 'DestashPolishes';
    let code = api.filter(a => a.Name == controller)[0].Code;
    let req = new DestashPolishesRequest();
    req.Polishes = polishes;
    return this.Post<DestashPolishesResponse>(controller, req, code);
  }

  UploadPolishImages(polishID: string, newImage: File): Observable<UpdatePolishesResponse> {
    let controller = 'UploadPolishImages';
    let code = api.filter(a => a.Name == controller)[0].Code;
    const formData = new FormData();
    formData.append('file', newImage);
    return this.Post<UpdatePolishesResponse>(controller, formData, code,"&polishID=" + polishID, null);
  }

  DeletePolishImage(polishID: string, imagePath: string): Observable<DeletePolishImageResponse> {
    let controller = 'DeletePolishImage';
    let code = api.filter(a => a.Name == controller)[0].Code;
    let req = new DeletePolishImageRequest();
    let pathParts =  imagePath.split("/");
    req.fileName = pathParts[2];
    req.imagePath = `${pathParts[0]}/${pathParts[1]}`;
    req.polishID = polishID;
    return this.Post<DeletePolishImageResponse>(controller, req, code,"", null);
  }
}
