// export interface Item {
//     Id: number;
//     Timestamp: string;
//     CreatedOn: string;
//     LastEditedOn: string | null;
//     Name: string;
//     ItemTypeId: number;
//     Quantity: number;
//     Location: string;
//     Description: string;
//     Notes: string;
//     InternalNotes: string;
//     GiftFromName: string;
//     WasGift: boolean;
//     OrderId: number | null;
//     Destashed: boolean;
//     DestashQuantity: number | null;
//     BuyerName: string;
//     AskingPrice: number | null;
//     SoldPrice: number | null;
//     SaleStatus: string;
//     ShippingProviderId: number | null;
//     TrackingNumber: string;
//     ColorId: number | null;
//     BrandId: number | null;
//     ColorNumber: number | null;
//     Label: string;
//     Coats: number;
//     HasBeenTried: boolean;
//     ShapeId: number | null;
//     Archived: boolean;
//     Brand: Brand;
//     Color: Color;
//     ItemsImages: ItemsImage[];
//     PolishesGlitterColors: PolishesGlitterColor[];
//     PolishesPolishTypes: PolishesPolishType[];
//     PolishesSecondaryColors: PolishesSecondaryColor[];
// }

export interface ItemsImage {
    Id: number;
    ItemId: number;
    Description: string;
    Notes: string;
    MakerImage: boolean | null;
    PublicImage: boolean | null;
    DisplayImage: boolean | null;
    ImagePath: string;
}

export interface PolishesGlitterColor {
    Id: number;
    ItemId: number;
    ColorId: number;
}

export interface PolishesPolishType {
    Id: number;
    ItemId: number;
    PolishTypeId: number;
}

export interface PolishesSecondaryColor {
    Id: number;
    ItemId: number;
    ColorId: number;
}

export interface Color {
    ID: number;
    Name: string;
    Description: string;
    IsPrimary: boolean | null;
    IsSecondary: boolean | null;
    IsGlitter: boolean;
}

export class Brand {
    ID: number;
    Name: string;
    Description: string;
    CategoryID: number;
    PolishBrand: boolean | null;
    PlateBrand: boolean;
    //Category: BrandCategory;
}

export interface PolishType {
    ID: number;
    Name: string;
    Description: string;
}


export class Item {
    ID: string;
    Name: string;
    CreatedOn: Date;
    Quantity: number = 1;
    Location: string;
    Description: string;
    Notes: string;
    InternalNotes: string;
    GiftFromName: string;
    WasGift: boolean;
    Destashed: boolean;
    DestashQuantity: number;
    BuyerName: string;
    AskingPrice: number | null;
    SoldPrice: number | null;
    SaleStatus: string;
    TrackingNumber: string;
    ColorNumber: number;
    Label: string;
    Coats: number = 1;
    HasBeenTried: boolean;
    Archived: boolean;
    BrandName: string;
    BrandCategory: string;
    Shipping: Shipping;
    ColorName: string;
    SecondaryColors: string;
    GlitterColors: string;
    PolishTypes: string;
    ImagePaths: string;
}

export interface Shipping {
    Name: string;
    TrackingBaseURL: string;
}