import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-location-change-dialog',
  templateUrl: './bulk-location-change-dialog.component.html',
  styleUrls: ['./bulk-location-change-dialog.component.scss']
})
export class BulkLocationChangeDialogComponent implements OnInit {

  Helmer: string = "";
  Drawer: string = "";

  constructor(
    public dialogRef: MatDialogRef<BulkLocationChangeDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  onSubmitClick(): void {
    let ret = this.Drawer ? this.Helmer + "-" + this.Drawer : this.Helmer;
    this.dialogRef.close(ret);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }
}
