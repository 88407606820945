<h1 mat-dialog-title>Random!</h1>
<div mat-dialog-content>
    <div class="container" fxLayout="wrap" fxLayout.xs="column" fxLayoutAlign="start" fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div class="item" fxFlex="100%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                <mat-form-field fxFlex="25%">
                    <mat-label>Brand Name</mat-label>
                    <input tab-directive matInput placeholder="Brand Name" [(ngModel)]="BrandName"
                        [matAutocomplete]="autobrand" [formControl]="brandControl">
                    <mat-autocomplete #autobrand="matAutocomplete" autoActiveFirstOption="true">
                        <mat-option *ngFor="let option of FilteredBrands | async" [value]="option.Name">
                            {{option.Name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field fxFlex="25%">
                    <mat-label>Color</mat-label>
                    <input tab-directive matInput placeholder="Color" [(ngModel)]="ColorName"
                        [matAutocomplete]="autocolor" [formControl]="colorControl">
                    <mat-autocomplete #autocolor="matAutocomplete" autoActiveFirstOption="true">
                        <mat-option *ngFor="let option of FilteredColors | async" [value]="option.Name">
                            {{option.Name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field floatLabel="always" appearance="none" fxFlex="25%">
                    <mat-label>Include Tried</mat-label>
                    <mat-slide-toggle [(ngModel)]="IncludeTried">
                        <span class="text-invisible"></span>
                    </mat-slide-toggle>
                    <textarea matInput hidden></textarea>
                </mat-form-field>

                <mat-form-field floatLabel="always" appearance="none" fxFlex="25%">
                    <mat-label>Require Location</mat-label>
                    <mat-slide-toggle [(ngModel)]="RequireLocation">
                        <span class="text-invisible"></span>
                    </mat-slide-toggle>
                    <textarea matInput hidden></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="SelectedPolish" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                    <mat-form-field fxFlex="20%">
                        <mat-label>Brand Name</mat-label>
                        <input matInput disabled placeholder="Brand Name" [(ngModel)]="SelectedPolish.BrandName">
                    </mat-form-field>

                    <mat-form-field fxFlex="20%">
                        <mat-label>Polish Name</mat-label>
                        <input matInput disabled placeholder="Polish Name" [(ngModel)]="SelectedPolish.Name">
                    </mat-form-field>

                    <mat-form-field fxFlex="20%">
                        <mat-label>Color</mat-label>
                        <input matInput disabled placeholder="Color" [(ngModel)]="SelectedPolish.ColorName">
                    </mat-form-field>

                    <mat-form-field fxFlex="20%">
                        <mat-label>Color Number</mat-label>
                        <input matInput disabled placeholder="Color Number" [(ngModel)]="SelectedPolish.ColorNumber">
                    </mat-form-field>

                    <mat-form-field fxFlex="20%">
                        <mat-label>Location</mat-label>
                        <input matInput disabled placeholder="Location" [(ngModel)]="SelectedPolish.Location">
                    </mat-form-field>
                </div>
            </div>
            <button mat-raised-button cdkFocusInitial (click)="pickRandom()" color="primary">Random!</button>
            <span class="error">{{ErrorText}}</span>
        </div>
    </div>
</div>