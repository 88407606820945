import { Directive, AfterViewInit } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';


@Directive({ selector: '[tab-directive]' })
export class TabDirective implements AfterViewInit {
  observable: any;
  constructor(
    private auto: MatAutocomplete
  ) { }

  ngAfterViewInit() {
    this.auto._keyManager.onKeydown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "Tab":
          if (this.auto.isOpen) {
            const option = this.auto.options.find(x => x.active);
            if (option) option.select();
            event.preventDefault();
          } else this.auto._keyManager.tabOut.next();
          break;
        case "ArrowDown":
          this.auto._keyManager.setNextItemActive();
          break;

        case "ArrowUp":
          this.auto._keyManager.setPreviousItemActive();
          break;
      }
    };
  }
}