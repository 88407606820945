import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { PolishWarehouseApiService } from '../services/polish-warehouse-service';
import { Item } from '../core/models/polish-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { BulkLocationChangeDialogComponent } from '../bulk-location-change-dialog/bulk-location-change-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserService } from '../services/user-service';
import { PolishViewComponent } from '../polish-view/polish-view.component';
import { DatePipe } from '@angular/common';
import { RandomPolishComponent } from '../random-polish/random-polish.component';

enum PageView {
  main = 0,
  archived = 1,
  destash = 2,
  last = 3
}

@Component({
  selector: 'app-polish-list',
  templateUrl: './polish-list.component.html',
  styleUrls: ['./polish-list.component.scss']
})
export class PolishListComponent implements OnInit {
  //Data variables
  basicFilter: string = "";
  advancedSearchBrandName: string = "";
  advancedSearchPolishName: string = "";
  advancedSearchDescription: string = "";
  advancedSearchLabel: string = "";
  advancedSearchCoats: number = 0;
  advancedSearchTried: boolean = false;
  advancedSearchGift: boolean = false;
  advancedSearchbyGift: boolean = false;
  advancedSearchbyTried: boolean = false;
  advancedSearchLocation: string = "";
  advancedSearchColorName: string = "";
  advancedSearchSecondaryColor: string = "";
  advancedSearchGlitterColor: string = "";
  advancedSearchPolishType: string = "";
  lastPolishes: Item[] = null;
  polishes: Item[] = null;
  destashPolishes: Item[] = null;
  archivedPolishes: Item[] = null;

  //Page State variables
  IsWait: boolean = false;
  modalWidth: string = "500px";
  modalHeight: string = "500px";
  validUser: boolean = false;
  currentPageView: PageView = PageView.main;


  //Table Variables
  dataSource: MatTableDataSource<Item>;
  defaultFilterPredicate?: (data: any, filter: string) => boolean;
  DisplayedColumns: string[] = [
    'BrandName',
    'NameView',
    'Description',
    'ColorName',
    'Coats',
    'HasBeenTried'];
  selection = new SelectionModel<Item>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private service: PolishWarehouseApiService, private userService: UserService, private _snackBar: MatSnackBar, private dialog: MatDialog, private datePipe: DatePipe) { }

  async ngOnInit() {
    this.validUser = await this.userService.validateUser();
    this.GetPolishes();
  }

  //Table functions
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  changeSelectedLocations(): void {
    const dialogRef = this.dialog.open(BulkLocationChangeDialogComponent, {
      width: this.modalWidth
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.selection.selected.forEach(item => {
          item.Location = result;
        });
        this.UpdatePolishes(this.selection.selected);
      }
    });
  }

  unDestashSelected(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: this.modalWidth,
      data: { message: `Are you sure you want to undo the destash on ${this.selection.selected.length.toString()} polishes?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach(polish => {
          polish.Destashed = false;
          polish.DestashQuantity = 0;
        });
        this.UpdatePolishes(this.selection.selected);
      }
    });
  }

  unDeleteSelected(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: this.modalWidth,
      data: { message: `Are you sure you want to undo the delete on ${this.selection.selected.length.toString()} polishes?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selection.selected.forEach(polish => {
          polish.Archived = false;
        });
        this.UpdatePolishes(this.selection.selected);
      }
    });
  }

  destashSelected(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: this.modalWidth,
      data: { message: `Are you sure you want to destash ${this.selection.selected.length.toString()} polishes?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.DestashPolishes(this.selection.selected);
      }
    });
  }

  deleteSelected(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: this.modalWidth,
      data: { message: `Are you sure you want to delete ${this.selection.selected.length.toString()} polishes?` }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.DeletePolishes(this.selection.selected);
      }
    });
  }

  formatDate(date: Date): string {
    let formated = this.datePipe.transform(date, "MM/dd/yyyy");
    return formated;

  }

  applyFilter(event: Event): void {
    this.resetAdvancedSearch();
    this.dataSource.filterPredicate = this.defaultFilterPredicate;
    const filterValue = (event.target as HTMLInputElement).value;
    this.basicFilter = filterValue.trim().toLowerCase();
    this.dataSource.filter = this.basicFilter;
  }

  getFilterPredicate() {
    return (row: Item, filters: string) => {
      const matchFilter = [];

      // Fetch data from row
      const columnBrandName = row.BrandName;
      const columnPolishName = row.Name;
      const columnDescription = row.Description ?? "";
      const columnLabel = row.Label;
      const columnCoats = row.Coats;
      const columnTried = row.HasBeenTried;
      const columnGift = row.WasGift;
      const columnLocation = row.Location ?? "";
      const columnColorName = row.ColorName;
      const columnSecondaryColors = row.SecondaryColors ?? "";
      const columnGlitterColors = row.GlitterColors ?? "";
      const columnPolishTypes = row.PolishTypes ?? "";

      // verify fetching data by our searching values
      const customFilterBN = columnBrandName.toLowerCase().includes(this.advancedSearchBrandName.toLowerCase());
      const customFilterPN = columnPolishName.toLowerCase().includes(this.advancedSearchPolishName.toLowerCase());
      const customFilterD = columnDescription.toLowerCase().includes(this.advancedSearchDescription.toLowerCase());
      const customFilterLA = columnLabel.toLowerCase().includes(this.advancedSearchLabel.toLowerCase());
      const customFilterCO = columnCoats == this.advancedSearchCoats;
      const customFilterTRIED = columnTried === this.advancedSearchTried;
      const customFilterGIFT = columnGift === this.advancedSearchGift;
      const customFilterLO = columnLocation.toLowerCase().includes(this.advancedSearchLocation.toLowerCase());
      const customFilterCOLOR = columnColorName.toLowerCase().includes(this.advancedSearchColorName.toLowerCase());
      const customFilterSECONDARY = columnSecondaryColors.toLowerCase().includes(this.advancedSearchSecondaryColor.toLowerCase());
      const customFilterGLITTER = columnGlitterColors.toLowerCase().includes(this.advancedSearchGlitterColor.toLowerCase());
      const customFilterPOLISHTYPE = columnPolishTypes.toLowerCase().includes(this.advancedSearchPolishType.toLowerCase());

      // push boolean values into array
      matchFilter.push(customFilterBN);
      matchFilter.push(customFilterPN);
      matchFilter.push(customFilterD);
      matchFilter.push(customFilterLA);
      if (this.advancedSearchCoats > 0)
        matchFilter.push(customFilterCO);
      if (this.advancedSearchbyTried)
        matchFilter.push(customFilterTRIED);
      if (this.advancedSearchbyGift)
        matchFilter.push(customFilterGIFT);
      matchFilter.push(customFilterLO);
      matchFilter.push(customFilterCOLOR);
      matchFilter.push(customFilterSECONDARY);
      matchFilter.push(customFilterGLITTER);
      matchFilter.push(customFilterPOLISHTYPE);

      // return true if all values in array is true
      // else return false
      return matchFilter.every(Boolean);
    };
  }

  advancedSearch(): void {
    this.basicFilter = "";
    if (this.dataSource) {
      this.dataSource.filterPredicate = this.getFilterPredicate();
      this.dataSource.filter = "!";
    }
  }

  resetAdvancedSearch(): void {
    this.advancedSearchBrandName = "";
    this.advancedSearchPolishName = "";
    this.advancedSearchDescription = "";
    this.advancedSearchLabel = "";
    this.advancedSearchCoats = 0;
    this.advancedSearchTried = false;
    this.advancedSearchGift = false;
    this.advancedSearchbyGift = false;
    this.advancedSearchbyTried = false;
    this.advancedSearchLocation = "";
    this.advancedSearchColorName = "";
    this.advancedSearchSecondaryColor = "";
    this.advancedSearchGlitterColor = "";
    this.advancedSearchPolishType = "";

    this.advancedSearch();
  }

  resetAllSearch(): void {
    this.resetAdvancedSearch();
    this.basicFilter = "";
  }

  Toast(message: string): void {
    this._snackBar.open(message, "Close", {
      duration: 3000,
      horizontalPosition: 'start'
    });
  }

  viewPolish(polish: Item) {
    console.log(polish);
    const dialogRef = this.dialog.open(PolishViewComponent, {
      width: "90%",
      height: "400px",
      data: polish
    });
  }

  mainPolishList() {
    this.currentPageView = PageView.main;
    this.switchPolishList();
  }
  destashPolishList() {
    this.currentPageView = PageView.destash;
    this.switchPolishList();
  }
  archivedPolishList() {
    this.currentPageView = PageView.archived;
    this.switchPolishList();
  }
  lastPolishList() {
    this.currentPageView = PageView.last;
    this.switchPolishList();
  }

  switchPolishList(): void {
    this.IsWait = true;
    this.selection.clear();
    this.resetAllSearch();
    switch (this.currentPageView) {
      case PageView.main:
        this.dataSource = new MatTableDataSource<Item>(this.polishes);
        this.defaultFilterPredicate = this.dataSource.filterPredicate;
        this.dataSource.paginator = this.paginator;
        break;
      case PageView.archived:
        this.dataSource = new MatTableDataSource<Item>(this.archivedPolishes);
        this.defaultFilterPredicate = this.dataSource.filterPredicate;
        this.dataSource.paginator = this.paginator;
        break;
      case PageView.destash:
        this.dataSource = new MatTableDataSource<Item>(this.destashPolishes);
        this.defaultFilterPredicate = this.dataSource.filterPredicate;
        this.dataSource.paginator = this.paginator;
        break;
      case PageView.last:
        this.dataSource = new MatTableDataSource<Item>(this.lastPolishes);
        this.defaultFilterPredicate = this.dataSource.filterPredicate;
        this.dataSource.paginator = this.paginator;
        break;
    };
    this.selection.clear();
    this.IsWait = false;
  }

  random() {
    const dialogRef = this.dialog.open(RandomPolishComponent, {
      data: this.polishes
    });
  }



  //API calls
  GetPolishes(): void {
    this.service.GetPolishes().subscribe(polishes => {
      if (this.validUser) {
        this.resetAllSearch();
        this.selection.clear();
        this.DisplayedColumns = ['select',
          'BrandName',
          'Name',
          'Description',
          'Label',
          'Coats',
          'HasBeenTried',
          'WasGift',
          'Location',
          'CreatedOn',
          'Action'];
      }
      let length = polishes.length < 50 ? polishes.length : 50;
      this.polishes = polishes.filter(polish => !polish.Archived).filter(polish => !polish.Destashed).sort((a, b) => (a.BrandName > b.BrandName) ? 1 : ((b.BrandName > a.BrandName) ? -1 : 0));
      this.lastPolishes = polishes.filter(polish => !polish.Archived).filter(polish => !polish.Destashed).sort((a, b) => (a.CreatedOn > b.CreatedOn) ? 1 : ((b.CreatedOn > a.CreatedOn) ? -1 : 0)).reverse().slice(0, length);
      this.archivedPolishes = polishes.filter(polish => polish.Archived).sort((a, b) => (a.BrandName > b.BrandName) ? 1 : ((b.BrandName > a.BrandName) ? -1 : 0));
      this.destashPolishes = polishes.filter(polish => polish.Destashed).sort((a, b) => (a.BrandName > b.BrandName) ? 1 : ((b.BrandName > a.BrandName) ? -1 : 0));

      this.dataSource = new MatTableDataSource<Item>(this.polishes);
      this.defaultFilterPredicate = this.dataSource.filterPredicate;
      this.dataSource.paginator = this.paginator;
      this.IsWait = false;
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

  UpdatePolishes(polishes: Item[]): void {
    this.IsWait = true;
    this.service.UpdatePolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      this.IsWait = false;
      this.GetPolishes();
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      },
      () => {
        this.IsWait = false;
      });
  }

  DeletePolishes(polishes: Item[]): void {
    this.IsWait = true;
    this.service.DeletePolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      this.IsWait = false;
      this.GetPolishes();
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

  DestashPolishes(polishes: Item[]): void {
    this.IsWait = true;
    this.service.DestashPolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      this.IsWait = false;
      this.GetPolishes();
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

}
