<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer  color="primary" class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'over'" [opened]="(isHandset$ | async) === true">
    <!-- <mat-sidenav #drawer  color="primary" class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="'over'" [opened]="(isHandset$ | async) === true"> -->
    <mat-toolbar>Polish Warehouse</mat-toolbar>
    <mat-nav-list>
      <a [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }' [routerLink]='["/"]'
        mat-list-item>Home</a>
        
    <a href="/.auth/login/aad" mat-list-item  *ngIf="!userInfo">Login</a>
    <a href="/.auth/logout" mat-list-item  *ngIf="userInfo">Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <a [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }' [routerLink]='["/"]' style="display: none;" class="home-icon"><mat-icon aria-label="Side nav toggle icon">home</mat-icon></a>
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <mat-toolbar color="primary" style="display: none;">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Polish Warehouse</span>
    </mat-toolbar>
    <div class="">
      <!-- <mat-card class="basic-container"> -->
      <router-outlet></router-outlet>
    <!-- </mat-card> -->
    </div>
  </mat-sidenav-content>
</mat-sidenav-container> 