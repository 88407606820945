import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-carousel-dialog',
  templateUrl: './image-carousel-dialog.component.html',
  styleUrls: ['./image-carousel-dialog.component.scss']
})
export class ImageCarouselDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImageCarouselDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public Images: string[]) { }
  ImageObject: any[] = [];

  ngOnInit(): void {
    this.Images.forEach(image => {

      this.ImageObject.push({
        image: image,
        thumbImage: image,
        title: ''
      });
    })
  }

  onDeleteClick(imageIndex: string): void {
    this.dialogRef.close(imageIndex);
  }

  onClick(): void {
    this.dialogRef.close(null);
  }



}
