<h1 mat-dialog-title>Update Location</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
        <mat-form-field>
            <mat-label>Helmer</mat-label>
            <input matInput placeholder="Helmer" [(ngModel)]="Helmer" cdkFocusInitial>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Drawer</mat-label>
            <input matInput placeholder="Drawer" [(ngModel)]="Drawer">
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onSubmitClick()">Save</button>
</div>