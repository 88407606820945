<ng-container *ngIf="overlay;else progressSpinner">
    <div class="overlay">
        <div class="center">
            <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
            <span>{{text}}</span>
        </div>
    </div>
    </ng-container>
    <ng-template #progressSpinner>
        <mat-spinner 
         [diameter]="diameter"
         [mode]="mode" 
         [color]="color"
         [strokeWidth]="strokeWidth"
         [value]="value">
        </mat-spinner>
    </ng-template>