import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Item, Color, PolishType, Brand } from '../core/models/polish-models';
import { PolishWarehouseApiService } from '../services/polish-warehouse-service';
import brands from '../configuration/brands.json';
import colors from '../configuration/colors.json';
import polishTypes from '../configuration/polishTypes.json';
import api from '../configuration/api.json';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ImageCarouselDialogComponent } from '../image-carousel-dialog/image-carousel-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserService } from '../services/user-service';

@Component({
  selector: 'app-polish-details',
  templateUrl: './polish-details.component.html',
  styleUrls: ['./polish-details.component.scss']
})
export class PolishDetailsComponent implements OnInit {
  Polish: Item = null;
  Polishes: Item[] = null;
  nameControl: FormControl;

  brandControl: FormControl;
  Brands: Brand[] = null;
  FilteredBrands: Observable<Brand[]>;

  colorControl: FormControl;
  Colors: Color[] = null;
  FilteredColors: Observable<Color[]>;

  PrimaryColors: Color[] = null;
  SecondaryColors: Color[] = null;
  GlitterColors: Color[] = null;
  PolishTypes: PolishType[] = null;

  selectedSecondaryControl: FormControl;
  SelectedSecondaryColors: Color[] = [];
  selectedGlitterControl: FormControl;
  SelectedGlitterColors: Color[] = [];
  selectedPolishTypeControl: FormControl;
  SelectedPolishTypes: PolishType[] = [];

  Images: string[] = [];
  ImageObject: any[] = [];
  ImagesToUpload: File[] = [];

  Helmer: string = "";
  Drawer: string = "";
  OriginalColorName: string = "";
  OriginalColorNumber: number = 0;

  //Page State variables
  IsWait: boolean = false;
  SpinnerText: string = "";
  modalWidth: string = "95%";
  modalHeight: string = "95%";
  validUser: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose Image';

  constructor(private service: PolishWarehouseApiService, private userService: UserService, private route: ActivatedRoute, private _snackBar: MatSnackBar, private dialog: MatDialog) { }

  async ngOnInit() {
    this.validUser = await this.userService.validateUser();
    this.nameControl = new FormControl("", [Validators.required]);
    this.Brands = brands.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    this.brandControl = new FormControl("", [Validators.required]);
    this.FilteredBrands = this.brandControl.valueChanges.pipe(
      startWith(''),
      map(value => this._brandfilter(value)),
    );

    this.Colors = colors.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    this.colorControl = new FormControl("", [Validators.required]);
    this.FilteredColors = this.colorControl.valueChanges.pipe(
      startWith(''),
      map(value => this._colorfilter(value)),
    );

    if (this.validUser) {
      this.PolishTypes = polishTypes.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
      this.PrimaryColors = this.Colors.filter(color => color.IsPrimary);
      this.SecondaryColors = this.Colors.filter(color => color.IsSecondary);
      this.GlitterColors = this.Colors.filter(color => color.IsGlitter);

      this.selectedSecondaryControl = new FormControl();
      this.selectedGlitterControl = new FormControl();
      this.selectedPolishTypeControl = new FormControl();

      this.route.queryParams.subscribe(params => {
        if (params["id"]) {
          this.GetPolish(params["id"]);
          this.GetPolishes().subscribe(polishes => {
            this.Polishes = polishes;
            this.IsWait = false;
            this.SpinnerText = "";
          },
            err => {
              console.log('HTTP Error', err);
              this.IsWait = false;
            });
        }
        else {
          this.Polish = new Item();
        }
      });
    } else { console.log("Not allowed so sorry."); }
  }

  onColorBlur(): void {
    if (this.Polishes == null) {
      this.GetPolishes().subscribe(polishes => {
        this.Polishes = polishes;
        this.IsWait = false;
        this.SpinnerText = "";
        this.FindNextColorNumber(this.colorControl.value);
      },
        err => {
          console.log('HTTP Error', err);
          this.IsWait = false;
        });
    } else { this.FindNextColorNumber(this.colorControl.value); }
  }

  FindNextColorNumber(color: string): void {
    if (color != this.OriginalColorName) {
      let filteredPolishes = this.Polishes.filter(polish => { return polish.ColorName == color }).sort((a, b) => (a.ColorNumber > b.ColorNumber) ? 1 : ((b.ColorNumber > a.ColorNumber) ? -1 : 0));
      let i = 1;
      filteredPolishes.forEach(polish => {
        if (polish.ColorNumber > i) {
          this.Polish.ColorNumber = i;
          return;
        }
        else {
          i++;
        }
      });
      this.Polish.ColorNumber = i; //In theory, if we hit this we have all other numbers up until this point filled.
      this.Toast(`Next number for ${color} is ${i.toString()}`);
    }
    else {
      console.log("Color is original color, skipping");
    }
  }

  IsSecondaryColorSelected(secondaryColor: Color): boolean {
    return this.SelectedSecondaryColors.filter(color => color.ID == secondaryColor.ID).length > 0;
  }

  SecondaryColorChanged(event: MatSlideToggleChange, color: Color): void {
    if (event.checked) {
      this.SelectedSecondaryColors.push(color);
    }
    else {
      const index = this.SelectedSecondaryColors.indexOf(color, 0);
      if (index > -1) {
        this.SelectedSecondaryColors.splice(index, 1);
      }
    }
  }

  IsGlitterColorSelected(glitterColor: Color): boolean {
    return this.SelectedGlitterColors.filter(color => color.ID == glitterColor.ID).length > 0;
  }

  GlitterColorChanged(event: MatSlideToggleChange, color: Color): void {
    if (event.checked) {
      this.SelectedGlitterColors.push(color);
    }
    else {
      const index = this.SelectedGlitterColors.indexOf(color, 0);
      if (index > -1) {
        this.SelectedGlitterColors.splice(index, 1);
      }
    }
  }

  IsPolishTypeSelected(polishType: PolishType): boolean {
    return this.SelectedPolishTypes.filter(pt => pt.ID == polishType.ID).length > 0;
  }

  PolishTypeChanged(event: MatSlideToggleChange, polishType: PolishType): void {
    if (event.checked) {
      this.SelectedPolishTypes.push(polishType);
    }
    else {
      const index = this.SelectedPolishTypes.indexOf(polishType, 0);
      if (index > -1) {
        this.SelectedPolishTypes.splice(index, 1);
      }
    }
  }

  showImageModal(): void {
    const dialogRef = this.dialog.open(ImageCarouselDialogComponent, {
      width: this.modalWidth,
      height: this.modalHeight,
      data: this.Images
    });

    dialogRef.afterClosed().subscribe(index => {
      if (index != null) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "500px",
          data: {
            message: `Are you sure you want to delete this image?`,
            image: this.Images[index]
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.DeletePolishImage(this.Polish.ID, this.Polish.ImagePaths?.split(',')[index]);
          }
        });

      }
    });
  }
  SavePolish(): void {
    this.Polish.Location = this.Drawer ? this.Helmer + "-" + this.Drawer : this.Helmer;
    if (this.selectedSecondaryControl.value) {
      this.SelectedSecondaryColors = this.selectedSecondaryControl.value;
    }
    if (this.selectedGlitterControl.value) {
      this.SelectedGlitterColors = this.selectedGlitterControl.value;
    }
    if (this.selectedPolishTypeControl.value) {
      this.SelectedPolishTypes = this.selectedPolishTypeControl.value;
    }
    this.Polish.SecondaryColors = this.SelectedSecondaryColors.length > 0 ? this.SelectedSecondaryColors.map(color => { return color.ID }).join(",") : null;
    this.Polish.GlitterColors = this.SelectedGlitterColors.length > 0 ? this.SelectedGlitterColors.map(color => { return color.ID }).join(",") : null;
    this.Polish.PolishTypes = this.SelectedPolishTypes.length > 0 ? this.SelectedPolishTypes.map(pt => { return pt.ID }).join(",") : null;
    this.Polish.Name = this.nameControl.value;
    this.Polish.ColorName = this.colorControl.value;
    this.Polish.BrandName = this.brandControl.value;
    this.Polish.Label = `${this.Polish.ColorName} ${this.Polish.ColorNumber.toString()}`;
    if (this.Polish.ID) {
      this.UpdatePolishes([this.Polish], this.ImagesToUpload);
    }
    else {
      this.CreatePolishes([this.Polish], this.ImagesToUpload);
    }
  }

  private _brandfilter(value: string): Brand[] {
    const filterValue = value.toLowerCase();

    return this.Brands.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private _colorfilter(value: string): Color[] {
    const filterValue = value.toLowerCase();

    return this.Colors.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  Toast(message: string): void {
    this._snackBar.open(message, "Close", {
      duration: 3000,
      horizontalPosition: 'start'
    });
  }

  uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
        this.ImagesToUpload.push(file);

        // HTML5 FileReader API
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let image = new Image();
          image.src = e.target.result;
        };

        reader.readAsDataURL(file);
      });;

      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose Image';
    }
  }

  //API calls
  GetPolish(id: string): void {
    this.IsWait = true;
    this.SpinnerText = "Loading Polish...";
    this.service.GetPolish(id).subscribe(polish => {
      this.Polish = polish;
      this.OriginalColorName = this.Polish.ColorName;
      this.OriginalColorNumber = this.Polish.ColorNumber;

      this.nameControl.setValue(polish.Name);
      this.brandControl.setValue(polish.BrandName);
      this.colorControl.setValue(polish.ColorName);

      if (this.Polish.Location) {
        let loc = this.Polish.Location.split('-');
        this.Helmer = loc[0];
        this.Drawer = loc[1];
      }

      if (this.Polish.SecondaryColors != null) {
        let ids = this.Polish.SecondaryColors?.split(',');
        ids.forEach(id => {
          this.SelectedSecondaryColors.push(this.SecondaryColors.filter(x => x.ID.toString() == id)[0]);
        });
        this.selectedSecondaryControl.setValue(this.SelectedSecondaryColors);
        this.selectedSecondaryControl.updateValueAndValidity();
      }

      if (this.Polish.GlitterColors != null) {
        let ids = this.Polish.GlitterColors?.split(',');
        ids.forEach(id => {
          this.SelectedGlitterColors.push(this.GlitterColors.filter(x => x.ID.toString() == id)[0]);
        });
        this.selectedGlitterControl.setValue(this.SelectedGlitterColors);
        this.selectedGlitterControl.updateValueAndValidity();
      }

      if (this.Polish.PolishTypes != null) {
        let ids = this.Polish.PolishTypes?.split(',');
        ids.forEach(id => {
          this.SelectedPolishTypes.push(this.PolishTypes.filter(x => x.ID.toString() == id)[0]);
        });
        this.selectedPolishTypeControl.setValue(this.SelectedPolishTypes);
        this.selectedPolishTypeControl.updateValueAndValidity();
      }
      if (this.Polish.ImagePaths != null) {
        this.Images = [];
        let paths = this.Polish.ImagePaths?.split(',');
        let url = api.filter(a => a.Name == "imageurl")[0].Code;
        let imagesas = api.filter(a => a.Name == "imagesas")[0].Code;
        paths.forEach(path => {
          let imageUrl = url + path + imagesas;
          this.Images.push(imageUrl);
          this.ImageObject.push({
            image: imageUrl,
            thumbImage: imageUrl,
            title: ''
          });
        });
      }
      this.IsWait = false;
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

  GetPolishes(): Observable<Item[]> {
    this.IsWait = true;
    this.SpinnerText = "Fetching Polishes...";
    return this.service.GetPolishes();
  }

  CreatePolishes(polishes: Item[], newImages: File[]): void {
    this.SpinnerText = "Creating Polish...";
    this.IsWait = true;
    this.service.CreatePolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      if (resp.IsSuccess) {
        this.Polish.ID = resp.polishIDs[0];
        if (newImages.length > 0) {
          this.SpinnerText = "Saving Image..."
          this.service.UploadPolishImages(resp.polishIDs[0], newImages[0]).subscribe(resp => {
            if (resp.Message)
              this.Toast(resp.Message);
            if (resp.ErrorMessage) {
              console.log(resp.ErrorMessage);
              if (resp.IsSuccess)
                this.GetPolish(this.Polish.ID);
            }
            this.IsWait = false;
          });
        } else {
          this.IsWait = false;
        }
      }
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

  UpdatePolishes(polishes: Item[], newImages: File[]): void {
    this.SpinnerText = "Saving Polish...";
    this.IsWait = true;
    this.service.UpdatePolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      if (newImages.length > 0) {
        this.SpinnerText = "Saving Image..."
        this.service.UploadPolishImages(this.Polish.ID, newImages[0]).subscribe(resp => {
          if (resp.Message)
            this.Toast(resp.Message);
          if (resp.ErrorMessage)
            console.log(resp.ErrorMessage);
          if (resp.IsSuccess)
            this.GetPolish(this.Polish.ID);
          this.IsWait = false;
        });
      } else {
        this.IsWait = false;
      }
    },
      err => {
        this.Toast("Opppsie Laa broke it...");
        console.log('HTTP Error', err);
        this.IsWait = false;
      },
      () => {
      });
  }

  DeletePolishes(polishes: Item[]): void {
    this.IsWait = true;
    this.service.DeletePolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      this.IsWait = false;
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

  DestashPolishes(polishes: Item[]): void {
    this.IsWait = true;
    this.service.DestashPolishes(polishes).subscribe(resp => {
      this.Toast(resp.Message);
      this.IsWait = false;
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }

  DeletePolishImage(polishID: string, imagePath: string): void {
    this.IsWait = true;
    this.SpinnerText = "Deleting Image..."
    this.service.DeletePolishImage(polishID, imagePath).subscribe(resp => {
      this.Toast(resp.Message);
      this.IsWait = false;
      this.SpinnerText = ""
    },
      err => {
        console.log('HTTP Error', err);
        this.IsWait = false;
      });
  }
}

