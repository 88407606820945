<app-spinner-overlay *ngIf="IsWait" text="{{SpinnerText}}" overlay="true">
</app-spinner-overlay>
<div *ngIf="Polish != null && validUser" style="margin:0 50px;">
    <div class="container" fxLayout="wrap" fxLayout.xs="column" fxLayoutAlign="start" fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div class="item" fxFlex="65%">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{Polish.Name}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div>
                        <h3>Polish Details</h3>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field fxFlex="25%">
                            <mat-label>Brand Name</mat-label>
                            <input matInput placeholder="Brand Name" [matAutocomplete]="autobrand"
                                [formControl]="brandControl">
                            <mat-autocomplete tab-directive autoActiveFirstOption #autobrand="matAutocomplete">
                                <mat-option *ngFor="let option of FilteredBrands | async" [value]="option.Name">
                                    {{option.Name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="brandControl.hasError('required')">
                                Brand is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Polish Name</mat-label>
                            <input matInput placeholder="Polish Name" [formControl]="nameControl">
                            <mat-error *ngIf="nameControl.hasError('required')">
                                Polish Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Color</mat-label>
                            <input matInput placeholder="Color" [matAutocomplete]="autoColor"
                                [formControl]="colorControl">
                            <mat-autocomplete tab-directive autoActiveFirstOption #autoColor="matAutocomplete"
                                (optionSelected)='onColorBlur()'>
                                <mat-option *ngFor="let option of FilteredColors | async" [value]="option.Name">
                                    {{option.Name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="colorControl.hasError('required')">
                                Color is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Color Number</mat-label>
                            <input matInput placeholder="Color Number" (blur)="onColorBlur()"
                                [(ngModel)]="Polish.ColorNumber">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field fxFlex="25%">
                            <mat-label>Secondary Colors</mat-label>
                            <mat-select [formControl]="selectedSecondaryControl" multiple>
                                <mat-option *ngFor="let Type of this.SecondaryColors" [value]="Type">
                                    {{Type.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="25%">
                            <mat-label>Glitter Colors</mat-label>
                            <mat-select [formControl]="selectedGlitterControl" multiple>
                                <mat-option *ngFor="let Type of this.GlitterColors" [value]="Type">
                                    {{Type.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="25%">
                            <mat-label>Polish Types</mat-label>
                            <mat-select [formControl]="selectedPolishTypeControl" multiple>
                                <mat-option *ngFor="let Type of this.PolishTypes" [value]="Type">
                                    {{Type.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <h3>Inventory Details</h3>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field fxFlex="25%">
                            <mat-label>Quantity</mat-label>
                            <input matInput placeholder="Quantity" [(ngModel)]="Polish.Quantity">
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Coats</mat-label>
                            <input matInput placeholder="Coats" [(ngModel)]="Polish.Coats">
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Helmer</mat-label>
                            <input matInput placeholder="Helmer" [(ngModel)]="Helmer">
                        </mat-form-field>

                        <mat-form-field fxFlex="25%">
                            <mat-label>Drawer</mat-label>
                            <input matInput placeholder="Drawer" [(ngModel)]="Drawer">
                        </mat-form-field>
                    </div>

                    <div>
                        <h3>Misc</h3>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field floatLabel="always" appearance="none" fxFlex="25%">
                            <mat-label>Has Been Tried</mat-label>
                            <mat-slide-toggle [(ngModel)]="Polish.HasBeenTried">
                                <span class="text-invisible">Tried</span>
                            </mat-slide-toggle>
                            <textarea matInput hidden></textarea>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" appearance="none" fxFlex="25%">
                            <mat-label>Gift</mat-label>
                            <mat-slide-toggle [(ngModel)]="Polish.WasGift">
                                <span class="text-invisible">Gift</span>
                            </mat-slide-toggle>
                            <textarea matInput hidden></textarea>
                        </mat-form-field>
                        <mat-form-field fxFlex="25%">
                            <mat-label>Gift Name</mat-label>
                            <input matInput placeholder="Gift Name" [(ngModel)]="Polish.GiftFromName">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFlexAlign="start">
                        <mat-form-field fxFlex="50%">
                            <mat-label>Description</mat-label>
                            <textarea matInput placeholder="Description" [(ngModel)]="Polish.Description"
                                rows="6"></textarea>
                        </mat-form-field>

                        <mat-form-field fxFlex="50%">
                            <mat-label>Notes</mat-label>
                            <textarea matInput placeholder="Notes" [(ngModel)]="Polish.Notes" rows="6"></textarea>
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button (click)="SavePolish()" color="primary">Save</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="item" fxFlex="35%">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Media</mat-card-title>
                </mat-card-header>
                <img mat-card-image *ngIf="Images.length == 0" class="img-polish-default-display"
                    src="assets/images/default.png">
                <ng-image-slider #nav [images]="ImageObject" [infinite]="false" [autoSlide]="1"
                    [imageSize]="{width: '50%'}" [manageImageRatio]="true" slideImage="1"></ng-image-slider>
                <mat-card-content>
                    <mat-form-field style="width: 100%;">
                        <div>
                            <mat-toolbar>
                                <!-- Display files names -->
                                <input matInput [(ngModel)]="fileAttr" readonly name="name" />

                                <!-- Browse Button -->
                                <button mat-button>
                                    Browse
                                </button>
                            </mat-toolbar>

                            <!-- Fetch selected filed on change -->
                            <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                name="uploadFile" accept="image/*" />
                        </div>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>