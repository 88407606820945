import { Injectable } from "@angular/core";
import { UserInfo } from "../core/models/UserInfo";

@Injectable()
export class UserService {
    constructor() {
    }

    private async getUserInfo(): Promise<UserInfo> {
        try {
            const response = await fetch('/.auth/me');
            const payload = await response.json();
            const { clientPrincipal } = payload;
            return clientPrincipal;
        } catch (error) {
            console.error('No profile could be found');
            return undefined;
        }
    }

    async validateUser(): Promise<boolean> {
        let userInfo = await this.getUserInfo();
        let hasRole = userInfo?.userRoles.includes("polishadmin");
        return hasRole;
    }
}