<app-spinner-overlay *ngIf="IsWait" text="Loading" overlay="true">
</app-spinner-overlay>
<div *ngIf="dataSource != null" style="margin:0 50px;">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Advanced Search
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <form (ngSubmit)="advancedSearch()">
                <div>
                    <mat-form-field>
                        <mat-label>Brand Name</mat-label>
                        <input matInput placeholder="Brand Name" [(ngModel)]="advancedSearchBrandName"
                            name="advancedSearchBrandName">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Polish Name</mat-label>
                        <input matInput placeholder="Polish Name" [(ngModel)]="advancedSearchPolishName"
                            name="advancedSearchPolishName">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="Description" [(ngModel)]="advancedSearchDescription"
                            name="advancedSearchDescription">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Label</mat-label>
                        <input matInput placeholder="Label" [(ngModel)]="advancedSearchLabel"
                            name="advancedSearchLabel">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Color Name</mat-label>
                        <input matInput placeholder="Color Name" [(ngModel)]="advancedSearchColorName"
                            name="advancedSearchColorName">
                    </mat-form-field>

                    <mat-form-field *ngIf="validUser">
                        <mat-label>Secondary Color</mat-label>
                        <input matInput placeholder="Secondary Color" [(ngModel)]="advancedSearchSecondaryColor"
                            name="advancedSearchSecondaryColor">
                    </mat-form-field>

                    <mat-form-field *ngIf="validUser">
                        <mat-label>Glitter Color</mat-label>
                        <input matInput placeholder="Glitter Color" [(ngModel)]="advancedSearchGlitterColor"
                            name="advancedSearchGlitterColor">
                    </mat-form-field>

                    <mat-form-field *ngIf="validUser">
                        <mat-label>Polish Type</mat-label>
                        <input matInput placeholder="Polish Type" [(ngModel)]="advancedSearchPolishType"
                            name="advancedSearchPolishType">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field *ngIf="validUser">
                        <mat-label>Location</mat-label>
                        <input matInput placeholder="Location" [(ngModel)]="advancedSearchLocation"
                            name="advancedSearchLocation">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Coats</mat-label>
                        <input matInput placeholder="Coats" [(ngModel)]="advancedSearchCoats"
                            name="advancedSearchCoats">
                    </mat-form-field>

                    <mat-form-field floatLabel="always" appearance="none">
                        <mat-label>Search by Tried?</mat-label>
                        <mat-slide-toggle [(ngModel)]="advancedSearchbyTried" name="advancedSearchbyTried">
                            <span class="text-invisible">Tried</span>
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" appearance="none">
                        <mat-label>Tried</mat-label>
                        <mat-slide-toggle [(ngModel)]="advancedSearchTried" name="advancedSearchTried">
                            <span class="text-invisible">Tried</span>
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                    <mat-form-field *ngIf="validUser" floatLabel="always" appearance="none">
                        <mat-label>Search by Gift?</mat-label>
                        <mat-slide-toggle [(ngModel)]="advancedSearchbyGift" name="advancedSearchbyGift">
                            <span class="text-invisible">Gift</span>
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>

                    <mat-form-field *ngIf="validUser" floatLabel="always" appearance="none">
                        <mat-label>Gift</mat-label>
                        <mat-slide-toggle [(ngModel)]="advancedSearchGift" name="advancedSearchGift">
                            <span class="text-invisible">Gift</span>
                        </mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>
                <div class="advancedSearchFooter">
                    <button mat-raised-button type="submit" color="primary">Search</button>
                    <button mat-raised-button type="button" (click)="resetAdvancedSearch()"
                        color="primary">Reset</button>
                </div>
            </form>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-form-field class="filter-input-full">
        <mat-label>Filter</mat-label>
        <input matInput [(ngModel)]="basicFilter" (keyup)="applyFilter($event)">
    </mat-form-field>
    <table style="width: 100%;" mat-table [dataSource]="dataSource" class="" matSort matSortActive="ID"
        matSortDisableClear matSortDirection="desc">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <!-- BrandName Column -->
        <ng-container matColumnDef="BrandName">
            <th mat-header-cell *matHeaderCellDef>Brand</th>
            <td mat-cell *matCellDef="let Polish">{{Polish.BrandName}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let Polish"><a [routerLink]="['/polish-details']"
                    [queryParams]="{id: Polish.ID }">{{Polish.Name}}</a></td>
        </ng-container>

        <!-- Color Name Column -->
        <ng-container matColumnDef="ColorName">
            <th mat-header-cell *matHeaderCellDef>Color Name</th>
            <td mat-cell *matCellDef="let Polish">{{Polish.ColorName}}</td>
        </ng-container>

        <!-- NameView Column -->
        <ng-container matColumnDef="NameView">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let Polish"><button mat-button
                    (click)="viewPolish(Polish)">{{Polish.Name}}</button></td>
        </ng-container>

        <!-- CreatedOn Column -->
        <ng-container matColumnDef="CreatedOn">
            <th mat-header-cell *matHeaderCellDef>Created Date</th>
            <td mat-cell *matCellDef="let Polish">{{formatDate(Polish.CreatedOn)}}</td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let Polish">{{Polish.Description}}</td>
        </ng-container>

        <!-- Label Column -->
        <ng-container matColumnDef="Label">
            <th mat-header-cell *matHeaderCellDef>Label</th>
            <td mat-cell *matCellDef="let Polish">{{Polish.Label}}</td>
        </ng-container>

        <!-- Coats Column -->
        <ng-container matColumnDef="Coats">
            <th mat-header-cell *matHeaderCellDef>Coats</th>
            <td mat-cell *matCellDef="let Polish">{{Polish.Coats}}</td>
        </ng-container>

        <!-- HasBeenTried Column -->
        <ng-container matColumnDef="HasBeenTried">
            <th mat-header-cell *matHeaderCellDef>Tried</th>
            <td mat-cell *matCellDef="let Polish">
                <mat-slide-toggle disabled="disabled" [(ngModel)]="Polish.HasBeenTried"></mat-slide-toggle>
            </td>

        </ng-container>

        <!-- WasGift Column -->
        <ng-container matColumnDef="WasGift">
            <th mat-header-cell *matHeaderCellDef>Gift</th>
            <td mat-cell *matCellDef="let Polish">
                <mat-slide-toggle disabled="disabled" [(ngModel)]="Polish.WasGift"></mat-slide-toggle>
            </td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="Location">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let Polish">{{Polish.Location}}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef disableClear class="pull-right">
                <a [routerLink]="['/polish-details']" [state]="{data: null }">
                    <mat-icon style="cursor: pointer;">add</mat-icon>
                </a>
                <a [matMenuTriggerFor]="menu">
                    <mat-icon style="cursor: pointer;">more_vert</mat-icon>
                </a>
                <mat-menu #menu="matMenu">

                    <button mat-menu-item (click)="mainPolishList()">
                        <mat-icon>list</mat-icon>
                        <span>Full List</span>
                    </button>
                    <button mat-menu-item (click)="destashPolishList()">
                        <mat-icon>list</mat-icon>
                        <span>Destashed List</span>
                    </button>
                    <button mat-menu-item (click)="archivedPolishList()">
                        <mat-icon>list</mat-icon>
                        <span>Archived List</span>
                    </button>
                    <button mat-menu-item (click)="lastPolishList()">
                        <mat-icon>list</mat-icon>
                        <span>View Last 50</span>
                    </button>
                    <button mat-menu-item (click)="random()">
                        <mat-icon>shuffle</mat-icon>
                        <span>Random!</span>
                    </button>
                    <button mat-menu-item *ngIf="selection.hasValue()" (click)="changeSelectedLocations()">
                        <mat-icon>edit</mat-icon>
                        <span>Update zone of selected</span>
                    </button>
                    <button mat-menu-item *ngIf="selection.hasValue()" (click)="destashSelected()">
                        <mat-icon>archive</mat-icon>
                        <span>Destash selected</span>
                    </button>
                    <button mat-menu-item *ngIf="selection.hasValue()" (click)="deleteSelected()">
                        <mat-icon>delete</mat-icon>
                        <span>Delete selected</span>
                    </button>
                    <button mat-menu-item *ngIf="selection.hasValue()" (click)="unDestashSelected()">
                        <mat-icon>undo</mat-icon>
                        <span>Undo Destash selected</span>
                    </button>
                    <button mat-menu-item *ngIf="selection.hasValue()" (click)="unDeleteSelected()">
                        <mat-icon>undo</mat-icon>
                        <span>Undo Delete selected</span>
                    </button>
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let Polish" class="pull-right">
                <mat-icon style="cursor: pointer;" (click)="DestashPolishes([Polish])" matTooltip="Destash">archive
                </mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
        <tr mat-row *matRowDef="let Polish; columns: DisplayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8">No data...</td>
        </tr>
    </table>

</div>
<div style="margin:0 50px;" class="{{dataSource != null ? '' : 'hide'}}">
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>