import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Brand, Color, Item } from '../core/models/polish-models';
import brands from '../configuration/brands.json';
import colors from '../configuration/colors.json';
import polishTypes from '../configuration/polishTypes.json';
import api from '../configuration/api.json';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-random-polish',
  templateUrl: './random-polish.component.html',
  styleUrls: ['./random-polish.component.scss']
})
export class RandomPolishComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RandomPolishComponent>,
    @Inject(MAT_DIALOG_DATA) public polishes: Item[]
  ) { }
  Polishes: Item[];
  SelectedPolish: Item = null;

  brandControl = new FormControl();
  Brands: Brand[] = null;
  FilteredBrands: Observable<Brand[]>;

  colorControl = new FormControl();
  Colors: Color[] = null;
  FilteredColors: Observable<Color[]>;

  ColorName: string = "";
  BrandName: string = "";
  ErrorText: string = "";
  IncludeTried: boolean = false;
  RequireLocation: boolean = false;

  ngOnInit(): void {
    this.Polishes = this.polishes;
    this.Brands = brands;
    this.FilteredBrands = this.brandControl.valueChanges.pipe(
      startWith(''),
      map(value => this._brandfilter(value)),
    );
    this.Colors = colors;
    this.FilteredColors = this.colorControl.valueChanges.pipe(
      startWith(''),
      map(value => this._colorfilter(value)),
    );
  }

  pickRandom(): void {
    let filteredPolishes = this.Polishes;

    if (this.BrandName != "") {
      filteredPolishes = filteredPolishes.filter(polish => polish.BrandName = this.BrandName);
    }
    if (this.ColorName != "") {
      filteredPolishes = filteredPolishes.filter(polish => polish.ColorName = this.ColorName);
    }
    if (!this.IncludeTried) {
      filteredPolishes = filteredPolishes.filter(polish => !polish.HasBeenTried);
    }
    if (this.RequireLocation) {
      filteredPolishes = filteredPolishes.filter(polish => polish.Location);
    }

    if (filteredPolishes.length > 0) {
      let index = Math.floor(Math.random() * (filteredPolishes.length - 0)) + 0;
      this.ErrorText = "";
      this.SelectedPolish = filteredPolishes[index];
    } else {
      let message = "Filter returned no results.";
      console.log(message);
      this.ErrorText = message;
      this.SelectedPolish = null;
    }
  }

  onClick(): void {
    this.dialogRef.close(null);
  }

  private _brandfilter(value: string): Brand[] {
    const filterValue = value.toLowerCase();

    return this.Brands.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private _colorfilter(value: string): Color[] {
    const filterValue = value.toLowerCase();

    return this.Colors.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

}
